import React, { lazy } from 'react'

const NotFound = lazy(() =>
  import('./NotFound').then(module => ({
    default: module.NotFound,
  }))
)

const ForgotPassword = lazy(() =>
  import('./ForgotPassword').then(module => ({
    default: module.ForgotPassword,
  }))
)

const ChangePassword = lazy(() =>
  import('./ChangePassword').then(module => ({
    default: module.ChangeEmployeePassword,
  }))
)

const ResetPassword = lazy(() =>
  import('./ResetPassword').then(module => ({
    default: module.ResetPassword,
  }))
)
const Profile = lazy(() =>
  import('./Profile').then(module => ({
    default: module.Profile,
  }))
)
const EditProfile = lazy(() =>
  import('./EditProfile').then(module => ({
    default: module.EditProfile,
  }))
)
const Logout = lazy(() =>
  import('./Logout').then(module => ({
    default: module.Logout,
  }))
)
const authenticationRoutes = [
  // {
  //   props: { path: '/login' },
  //   component: <Login />,
  // },
  {
    props: { path: '/authentication/404' },
    component: <NotFound />,
  },
  {
    props: { path: '/logout' },
    component: <Logout />,
  },
  {
    props: { path: '/forgot' },
    component: <ForgotPassword />,
  },

  {
    props: { path: '/profile/change-password' },
    component: <ChangePassword />,
  },

  {
    props: { path: `/resetPassword/:token` },
    component: <ResetPassword />,
  },
  {
    props: { path: '/profile' },
    component: <Profile />,
  },
  {
    props: { path: '/profile/edit' },
    component: <EditProfile />,
  },
]

export default authenticationRoutes
