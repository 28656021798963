import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Button, TextField } from '@material-ui/core'

import Logo from 'assets/images/HR-X.png'

import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { useForgotEmployeePasswordMutation } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { ForgotPasswordSchema } from 'yupValidation/yup'
import Background from 'assets/images/HR-Background.jpg'
import { yupResolver } from '@hookform/resolvers'
import './style.scss'

interface IForgotPasswordForm {
  email: string
}

export const ForgotPassword = props => {
  let history = useHistory()

  const [openAlert, setOpenAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/login')
  }
  const MESSAGE =
    'We have sent you an email. Please click on the link to reset your password'

  const [forgotEmployeePassword] = useForgotEmployeePasswordMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      console.log('data', data)
      setOpen(true)
    },
  })

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control } = useForm<
    IForgotPasswordForm
  >({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(ForgotPasswordSchema),
  })
  console.log(errors)

  const onSubmit = (data: any) => {
    forgotEmployeePassword({
      variables: {
        email: data.email,
      },
    })
    // .then(x=>{
    //  setOpen(true)
    //        history.push("/Login")
    // })
  }
  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Forgot Password',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        {/* <span className="logo-title">Sales Online Platform</span> */}
        <span className="page-title">Forgot Password</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Email*"
              control={control}
              inputRef={register({})}
            />

            <span
              className="forget-link"
              onClick={() => history.push('/login')}
            >
              Sign In Here
            </span>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
          >
            Send
          </Button>
        </form>
      </AuthLayout>
    </>
  )
}
