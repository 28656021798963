import React, { useContext } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { getAccessToken } from 'AccessToken'
import AppContext from '../Store/AppContext'

export const PrivateRoute = ({ children, ...rest }) => {
  const { globalState, dispatch } = useContext(AppContext as any) 
  const AccessToken = getAccessToken()
//
  return (
    <Route
      {...rest}
      render={({ location }) =>
        AccessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
